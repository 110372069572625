import de from './translations/de'
import en from './translations/en'
import es from './translations/es'
import zh from './translations/zh-Hans'

export default {
  de,
  en,
  es,
  zh
}
